.faq-bg{
    width: 100%;
    min-height: 200px;
}
.faq-bg>img{
    width: 100%;
    height: 100%;
}
.rotate{
    transform: rotate(180deg);
}
.faq-card{
    cursor: pointer;
    max-width: 100%;
    border: 2px solid rgba(255, 255, 255,0.8);
    padding:15px;
    border-radius: 2px;
    margin-bottom: 30px;
}
.faq-question{
    color:rgba(255, 255, 255,0.8);
    display: flex;
    font-weight: 600;
    font-size: 16px;
    justify-content: space-between;
    align-items: center;
    font-family: var(--font-secondary);
}
.down-icon{
    cursor: pointer;
    font-weight: 900;
    transition: 0.3s;
}
.faq-answer{
    color:rgba(255, 255, 255,1);
    padding-top: 5px;
    font-family: var(--font-secondary);
    font-weight: 500;
    font-size: 16px;
    transition: 6s;
}
.rotate{
    transform: rotate(180deg);
    transition: 0.5s;
}
.faq-container{
    max-width: 70%;
    margin: 40px auto;
    transition: 0.4s;
}
@media only screen and (max-width: 600px) {
    .faq-container{
        max-width: 90%;
    }
    .text-container-for-faq{
        max-width: 90%;
    }
}