.downloads{
    min-height: 100vh;
    background-color: var(--backgroundColor);
    margin: 0px auto;
    width: 100%;

}
.download-container-1{
    max-width: 70%;
    margin: 0px auto;
    padding: 0vh 0px;
}
.download-title{
    font-size: 30px;
    padding-top: 40px;

}

.downloads-list{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}
.download-container{
    margin: 20px;
}
.download-container>.one{
    width: 300px;
    display: flex;
    justify-content: center;
    gap: 10px;
    background-color:#303836;
    border-radius: 4px;
}
.image-cont{
    width: 150px;
    border-radius: 4px;
}
.image-cont>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;

}
.download-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--primaryGreen);
    color: var(--backgroundColor);
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    border-radius: 4px;
    margin: 30px 0px;
    cursor: pointer;
}
.download-titlex{
    font-size: 20px;
    font-weight: 400;
    color: rgb(201, 195, 195);
    margin: 0px 0px;
}
.download-descx{
    font-size: 16px;
    color: rgb(167, 171, 175);
}
.download-desc{
    padding: 4px;
    font-weight: 600;
    text-align: center;
}
@media only screen and (max-width: 600px) {
    .download-container-1{
        max-width: 90%;
        align-items: center;
    }
}