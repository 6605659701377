
.szh-menu{
    background-color:#303836;
    color: var(--primaryGreen);
}
.szh-menu__item{
    background-color:#303836;
}
.szh-menu__item:hover{
    background-color: var(--backgroundColor);
}
.mobilenav{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    height: 50px;
    width: 100%;
    background-color: var(--primaryGreen);
}
.menu-icon{
    font-size: 24px;
}
.logo-word{
    font-size: 24px;
    font-weight: 500;
}
.sidemenu{
    width: 0%;
    height: 100vh;
    transition: 1s;
    position: fixed;
    margin-top: 50px;
    z-index: 9999999;
    background-color: var(--primaryGreen);
}
.signup-login-btns{
    margin: 20px;
    padding: 20px;
    overflow: hidden;
}
.nav-image1{
    z-index: 999;
    margin-top:30px;
    overflow: visible;
    background-color:var(--primaryGreen);
    padding: 10px 60px 10px 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 120px;
    transform: skewX(-45deg);


}
.nav-image1>img{
    width: 120px;
    height: 94px;
    transform: skewX(45deg);
}