.home{
    min-height: 100vh;
    width: 100%;
    background-color: var(--primaryBlack);
}
.home-container{
    max-width: 700px;
    margin: 0px auto;
    padding: 40px;
}.home-title{
    font-size: 30px;
}
.team-card{
    background-color: #303836;
    width: 100%;
    padding: 16px;
    color: rgb(176, 170, 170);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    margin: 20px 0px;
    box-shadow: 0px 0px 5px rgb(55, 53, 53);

}
.team-name{
    font-size: 16px;
    border-radius: 8px;
    font-weight: 500;
}
.team-view{
    color: var(--primaryGreen);
    cursor: pointer;
}
.team-view:hover{
    text-decoration: underline;
}
.queries{
    min-height: 100vh;
    width: 100%;
    background-color: var(--primaryBlack);

}
.queries-container{
    max-width: 700px;
    margin: 0px auto;
    padding: 40px;
}
.queries-title{
    font-size: 30px;
}

.queries-card{
    font-size: 16px;
    background-color: #303836;
    color: rgb(173, 168, 168);
    margin: 10px;
    padding: 15px;
    border-radius: 4px;

}