.login{
    min-height: 100vh;
    width: 100%;
    padding-top: 10vh;
    background-color: var(--backgroundColor);
 
}
.login-form-container{
    max-width: 500px;
    margin: 0px auto;
    padding: 3vh 0;
    background-color: #282b2b;
    backdrop-filter: saturate(180%) blur(10px);
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1.3px);
    -webkit-backdrop-filter: blur(1.3px);
    border: 1px solid rgba(15, 15, 15, 0.3) 
}

.login-title{
    max-width: 100%;
    font-size: 35px;
    text-align: center;
}
.input-login{
    width: 70%;
    margin:30px auto;

}
.login-title-desc{
    max-width: 100%;
    font-size: 16px;
    color: rgb(149, 143, 143);
    margin-top: -3px;
    text-align: center;
}
.login-btn{
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--primaryGreen);
    color:var(--backgroundColor);
    font-size: 16px;
    font-weight: 500;
    padding: 8px 20px;
    border-radius: 4px;
    margin-bottom: 30px;
    cursor: pointer;


}
.submit-btn{
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 70px;
}